/* eslint-disable react/no-danger */
import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import styles from '../styles/pages/posts.module.scss';
import SEO from '../components/seo';

function BlogIndex({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className={styles.posts_page}>
        <h1>Posts</h1>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <Link
              style={{ boxShadow: `none` }}
              to={node.fields.slug}
              key={node.fields.slug}
            >
              <article>
                <header>
                  <h3>{title}</h3>
                  <small>{node.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt
                    }}
                  />
                </section>
              </article>
            </Link>
          );
        })}
      </div>
    </Layout>
  );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM D YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
